import {
  Box,
  Grid,
  IconButton,
  Typography,
  MenuItem
} from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";

import TextField from "./TextField"

import { useOptimizationChangesDispatch } from "../OptimizationsContext";

import { RULE_PRIORITIES_OPTIONS, AUDIENCE_SECTION, formFieldBuilder } from "../utils/optimizationFormUtils.js";

import OptimizationFieldGroup from "./OptimizationFieldGroup";

const AudienceTargetingField = ({ rule, errors = {}, currentPriorities }) => {
  const dispatch = useOptimizationChangesDispatch();
  const { priority, value = { value: [], action: "", type: "segment" }, editable } = rule;

  const handleUpdate = (value) =>
    dispatch({ action: "rule_updated", section: AUDIENCE_SECTION, ...rule, ...value });

  const handleRemove = (_event) =>
    dispatch({ action: "rule_removed", section: AUDIENCE_SECTION, ...rule });

  const listLabel = value.type === "segment" ? "Segments" : "Audiences";

  return (
    <Grid
      container
      direction="row"
      sx={{ mb: 2 }}
    >
      <Grid
        item
        container
        xs={12}
        justifyContent="space-between"
        columnSpacing={2}
        sx={{ mb: 1 }}
        direction="row"
      >
        <Grid item xs={4}>
          <TextField
            select
            size="small"
            label="Priority"
            value={priority}
            disabled={!editable}
            fullWidth
            error={"priority" in errors}
            helperText={errors.priority ?? ""}
            onChange={(event) => handleUpdate({ priority: event.target.value })}
          >
          <MenuItem value="">Select...</MenuItem>
            {(editable ? RULE_PRIORITIES_OPTIONS : [priority]).map(value => <MenuItem key={value} value={value} disabled={currentPriorities.includes(value)}>{value}</MenuItem>)}
          </TextField>
        </Grid>
        <Grid item xs={4}>
          <TextField
            select
            size="small"
            label="Target/Block"
            value={value.action ?? ""}
            disabled={!editable}
            fullWidth
            error={"action" in errors}
            helperText={errors.action ?? ""}
            onChange={(event) => handleUpdate({ value: { ...value, action: event.target.value } })}
          >
            <MenuItem value="" key="default">Select...</MenuItem>
            <MenuItem value="target" key="target">Target</MenuItem>
            <MenuItem value="block" key="block">Block</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={1}>
          {editable && (
            <IconButton aria-label="delete" onClick={handleRemove}>
              <DeleteIcon />
            </IconButton>
          )}
        </Grid>
      </Grid>
      <Grid item xs={11}>
        <TextField
          fullWidth
          size="small"
          variant="standard"
          label={listLabel}
          disabled={!editable}
          error={"value" in errors}
          helperText={errors.value ?? ""}
          onChange={(event) => {
            const currentValue = event.target.value;
            const newValue = currentValue === "" ? [] : currentValue.split(",")

            handleUpdate({ value: { ...value, value: newValue } })
          }}
          value={value.value.join(",")}
        />
      </Grid>
    </Grid>
  )
}

const AudienceFormSection = ({ rules }) => {
  return rules.length > 0 && (
    <Box sx={{ mb: 1 }}>
      <Typography variant="h5" sx={{ mb: 1 }}>Audience</Typography>
      {formFieldBuilder(OptimizationFieldGroup,"audience", { RuleField: AudienceTargetingField, rules })}
    </Box>
  )
}

export default AudienceFormSection;

